import { useState } from 'react'

import { Flex, Heading, Notice, Tabs } from '@applyboard/crystal-ui'
import { BulletFillIcon } from '@applyboard/ui-icons'
import styled from '@emotion/styled'
import { FileData, RefinedVersionedApplicationResourceWithFiles } from 'applications-types-lib'
import { find } from 'lodash'
import { Navigate, useParams } from 'react-router-dom'
import {
  ApplicationForms,
  ApplicationRequests,
  ApplicationTimeline,
} from '../../components/Application'
import { getFilesOfType } from '../../components/Application/ApplicationForms/utils'
import { DesktopHeader } from '../../components/Application/ApplicationHeader/DesktopHeader'
import ApplicationStateTag from '../../components/Application/ApplicationStateTag/ApplicationStateTag'
import { Loading } from '../../components/Loading'
import { NavBars } from '../../components/NavBars'
import { ApplicationState, useGetApplication } from '../../hooks'
import { up } from '../../utils'
import { DocumentTags } from '../../utils/enums'
import { BackToApplication } from '../CreateApplicationPage/BackToApplication'
import { TopMenu } from './TopMenu'

enum TabLabel {
  Submitted = 1,
  Requests,
}

export function ViewApplicationPage() {
  const { id } = useParams()
  const { isLoadingApplication, application } = useGetApplication({ id })
  const [tab, setTab] = useState(`${TabLabel.Submitted}`)
  const [currentStep, setCurrentStep] = useState(1)
  const selectedTab = currentStep

  if (isLoadingApplication) {
    return (
      <NavBars>
        <Loading />
      </NavBars>
    )
  }

  if (!application) {
    return <Navigate to="/not-found" />
  }

  if (application.attributes?.applicationState === ApplicationState.DRAFT) {
    return <Navigate to={`/applications/new?applicationId=${application.id}`} />
  }

  return (
    <NavBars largePage>
      <BackToApplication
        label={`${application.attributes?.programSelected?.program?.name} Application`}
      />
      <Flex direction="column" gap={6}>
        <Flex wrap={false} align="center" justify="between" gap={4}>
          <Flex
            hideBelow={selectedTab === 7 ? 'sm' : undefined}
            align={{ xs: 'start', md: 'center' }}
            direction={{ xs: 'column', md: 'row' }}
            gap={4}
          >
            <Flex hideBelow="md">
              <img
                src="/application-illustration.svg"
                alt={`Application for ${application.attributes?.programSelected?.program?.name}`}
              />
            </Flex>
            <Heading level={1} variant="headlineL">
              Application for {application.attributes?.programSelected?.program?.name}
            </Heading>
            <ApplicationStateTag state={application.attributes?.applicationState} />
          </Flex>
          <TopMenu applicationId={application.id} />
        </Flex>
        {application.attributes?.applicationState ===
          ApplicationState.DOCUMENTS_REQUESTED_IN_SCREENING ||
        application.attributes?.applicationState ===
          ApplicationState.DOCUMENTS_REQUESTED_IN_ASSESSMENT ? (
          <Flex grow={1}>
            <Notice intent="warning">
              Additional documents have been requested to complete the assessment of your
              application.
            </Notice>
          </Flex>
        ) : null}
        {application.attributes?.applicationState === ApplicationState.INITIAL_OFFER ? (
          <ApplicationFileNotice
            application={application}
            documentTag={DocumentTags.PRE_OFFER_LETTER}
            intent="primary"
            buttonText="View Pre-offer Letter"
            text="Congratulations! You have received a pre-offer decision. Click the button to preview your letter of acceptance."
          />
        ) : null}
        {application.attributes?.applicationState === ApplicationState.DECLINED ? (
          <ApplicationFileNotice
            application={application}
            documentTag={DocumentTags.DECLINED_LETTER}
            intent="negative"
            buttonText="View Letter"
            text="After careful consideration, we regret to inform you that your application has not been accepted."
          />
        ) : null}
        <Flex gap={{ xs: 6, md: 12 }} direction={{ xs: 'column', md: 'row' }}>
          <Flex direction="column" gap={4} basis={{ xs: '100%', md: 278 }}>
            <DesktopHeader application={application} showProgramName={false} />
            {tab === `${TabLabel.Submitted}` ? (
              <Flex hideBelow="md">
                <ApplicationTimeline
                  selectedTab={selectedTab}
                  setSelectedTab={tab => setCurrentStep(tab)}
                  application={application}
                />
              </Flex>
            ) : null}
          </Flex>
          <TabWrapper>
            <Tabs
              onChange={value => setTab(`${value}`)}
              value={`${tab}`}
              aria-label="Application Tabs"
              orientation="horizontal"
            >
              <Tabs.Item value={`${TabLabel.Submitted}`} label={TabLabel[TabLabel.Submitted]}>
                <Flex hideAbove="md" align="center" justify="center">
                  <ApplicationTimeline
                    selectedTab={selectedTab}
                    setSelectedTab={tab => setCurrentStep(tab)}
                    application={application}
                  />
                </Flex>
                <ApplicationForms
                  disabled
                  selectedTab={selectedTab}
                  application={application}
                  onSuccess={() => {
                    if (selectedTab <= 6) {
                      setCurrentStep(currentStep + 1)
                    }
                  }}
                />
              </Tabs.Item>
              <Tabs.Item
                value={`${TabLabel.Requests}`}
                label={TabLabel[TabLabel.Requests]}
                icon={
                  find(
                    application.attributes?.requestedDocuments,
                    r => r?.requestStatus === 'PENDING',
                  )
                    ? () => <BulletFillIcon intent="negative" />
                    : undefined
                }
              >
                <ApplicationRequests application={application} />
              </Tabs.Item>
            </Tabs>
          </TabWrapper>
        </Flex>
      </Flex>
    </NavBars>
  )
}

type ApplicationFileNoticeProps = {
  application: RefinedVersionedApplicationResourceWithFiles
  documentTag: DocumentTags
  intent: 'primary' | 'negative'
  text: string
  buttonText: string
}

function ApplicationFileNotice(props: ApplicationFileNoticeProps) {
  const documents = getFilesOfType(
    [props.documentTag],
    props.application.attributes?.files as FileData,
  )

  if (!Object.keys(documents).length) {
    return null
  }

  const [letterKey] =
    Object.entries(documents).find(([_, document]) => document?.activeRecord) ?? []

  if (!letterKey) {
    return null
  }

  const url = props.application.meta.files?.[letterKey]?.download?.url

  if (!url) {
    return null
  }

  return (
    <Flex>
      <Notice intent={props.intent}>
        {props.text}
        <Notice.ButtonLink href={url} mode="override_user_choice_and_open_in_new_tab">
          {props.buttonText}
        </Notice.ButtonLink>
      </Notice>
    </Flex>
  )
}

const TabWrapper = styled.div({
  width: '100%',
  [up('md')]: {
    width: 'calc(100% - 326px)',
  },
})
